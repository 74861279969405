<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <el-input clearable @clear="handlerSearchBtn" style="width: 250px" size="small" placeholder="Input search keyword" v-model="search.search_keyword"></el-input>
              &nbsp;
              <el-select v-model="search.search_by" size="small">
                <el-option v-for="(item, i) in search_by_options" :key="i" :value="item.value" :label="item.text" />
              </el-select> &nbsp;
              <el-date-picker
                v-model="search.date_range"
                type="daterange"
                size="small"
                :picker-options="date_picker_options"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date">
              </el-date-picker> &nbsp;
              <el-button :loading="loading.table" @click="handlerSearchBtn()" size="small" class="btn-primary" icon="el-icon-search">Search</el-button>
              <el-button :loading="loading.table" @click="downloadReport();" size="small" class="btn-secondary" icon="el-icon-download">Download</el-button>
            </div>
            <hr/>
            <div class="table-responsive">
              <table class="table table-hover" v-loading="loading.table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Invoice Number</th>
                    <th>Amount</th>
                    <th>Requested By</th>
                    <th>Status</th>
                    <th>Ref. Number</th>
                    <th>Receipt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in rows" :key="i">
                    <td>{{ item.date_str }}</td>
                    <td>{{ item.invoice_number }}</td>
                    <td>{{ item.topup_amount_str }}</td>
                    <td>
                      {{ item.creator ? item.creator.name : '-' }}
                    </td>
                    <td>
                      <el-tag size="mini" :type="item.tag_type">{{ item.status.replace('_', ' ').toUpperCase() }}</el-tag>
                    </td>
                    <td>{{ item.reference_number || '-' }}</td>
                    <td>
                      <a :href="item.topup_receipt_url" class="text-normal" target="_blank" v-if="item.status == 'paid' || item.status == 'waiting_payment'">
                        <font-awesome icon="file-pdf"/>&nbsp;Download Invoice
                      </a>
                      <span v-else>-</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <el-empty v-if="!rows.length" description="No histories"></el-empty>
            </div>
            <b-pagination v-if="total_row > search.per_page" v-model="currentPage" :total-rows="total_row" :per-page="search.per_page"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { isEqual } from 'lodash';
import workspaceApi from '../../../api/workspaces';
import topupApi from '../../../api/topup';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'TopupHistories',
  metaInfo: {
    title: 'Topup Histories',
  },
  data() {
    return {
      loading: {
        table: false,
      },
      search: {
        date_range: [
          this.$route.query?.start_date || moment().subtract(7, 'days'),
          this.$route.query?.end_date || moment(),
        ],
        page: this.$route.query?.page || 1,
        per_page: this.$route.query?.per_page || 10,
        search_keyword: this.$route.query?.search_keyword || '',
        search_by: this.$route.query?.search_by || 'invoice_number',
      },
      rows: [],
      total_row: 0,
      search_by_options: [
        {
          value: 'invoice_number',
          text: 'Invoice Number',
        },
        {
          value: 'reference_number',
          text: 'Ref. Number',
        },
      ],
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 90 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const start = moment().subtract(3, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        this.search.page = val;
        this.getList();
      },
    },
    userProfile() {
      return this.$store.state.auth.userProfile || {};
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace || {};
    },
    workspaceBalance() {
      return this.$store.state.workspace.workspaceBalance?.toLocaleString('id-ID');
    },
    workspaceFreeQuota() {
      return this.$store.state.workspace.workspaceFreeQuota;
    },
  },
  mounted() {
    this.getList();
    this.checkWorkspaceBalance();
  },
  methods: {
    async checkWorkspaceBalance() {
      if (this.activeWorkspace._id) {
        const response = await workspaceApi.check_balance({
          id: this.activeWorkspace._id,
        });
        await popupErrorMessages(response);
        this.$store.dispatch('workspace/setWorkspaceBalance', response.data);
      }
    },
    /* eslint-disable radix */
    handlerNextPrev(value) {
      if (value === 'next') {
        this.search.page = parseInt(this.search.page) + 1;
        this.search.previous_page = null;
        if (!this.search.next_page) {
          return;
        }
      } else {
        this.search.page = parseInt(this.search.page) - 1;
        this.search.next_page = null;
        if (!this.search.previous_page) {
          return;
        }
      }
      this.getList();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async getList(action = '') {
      const options = {
        page: this.search.page.toString(),
        per_page: this.search.per_page.toString(),
        start_date: moment(this.search.date_range[0]).format('YYYY-MM-DD'),
        end_date: moment(this.search.date_range[1]).format('YYYY-MM-DD'),
        search_by: this.search.search_by,
        search_keyword: this.search.search_keyword,
      };

      if (!isEqual(options, this.$route.query)) {
        this.$router.replace({ query: options });
      }
      this.loading.table = true;
      if (action === 'download') options.action = 'download';
      await topupApi.topupHistories({
        id: this.$route.params.workspace_id,
        query: options,
      }).then(({ data }) => {
        if (options.action === 'download') {
          this.$message({
            title: this.$t('general.success'),
            type: 'success',
            message: this.$t('general.waiting_download'),
            duration: 30 * 1000,
            showClose: true,
          });
          return;
        }
        this.total_row = data.count;
        this.rows = data.rows.map((v) => {
          v.date_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          v.topup_amount_str = `Rp. ${v.amount.toLocaleString('id')}`;
          v.tag_type = 'warning';
          v.topup_receipt_url = topupApi.downloadInvoices({
            workspace_id: this.$route.params.workspace_id,
            id: v.id,
          });
          if (v.status === 'paid') {
            v.tag_type = 'success';
          } else if (['expired', 'failed'].includes(v.status)) {
            v.tag_type = 'danger';
          } else if (v.status === 'cancelled') {
            v.tag_type = 'info';
          }
          return v;
        });
      });
      this.loading.table = false;
    },
    handlerSearchBtn() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.search.next_page = null;
      this.search.previous_page = null;
      this.search.page = 1;
      this.getList();
    },
    async downloadReport() {
      this.getList('download');
    },
  },
};
</script>
